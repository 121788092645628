<template>
  <div class="">
    <div class="absolute inset-x-0 top-0 z-20">
      <slot />
    </div>
    <div class="relative isolate overflow-hidden pt-14 lg:px-10 px-6 lg:min-h-[50rem] flex lg:flex-col justify-center">
      <div class="container py-16 flex flex-col flex-wrap gap-8 md:h-full">
        <div class="xl:pl-28 flex-grow lg:flex-grow-0">
          <BaseTitle
            level="h1"
            variant="primary"
            class="mb-8"
          >
            <span class="" v-text="titleChunks[0]" />
            <span
              v-if="titleChunks?.[1]"
              class="text-brand-secondary first-letter:capitalize block"
              v-text="titleChunks[1]"
            />
          </BaseTitle>
          <p
            v-for="(subtitle, idx) in subtitleChunks"
            :key="idx"
            class="text-brand-black text-xl xl:max-w-[56%]"
          >
            {{ subtitle.trim() }}.
          </p>
          <div class="flex gap-8 lg:items-center justify-start flex-col lg:flex-row mt-8">
            <BaseButtonLink
              variant="primary"
              class="md:w-fit"
              size="xl"
              :to="localePath(data?.main_button?.button_link)"
              @click="onClickCta"
            >
              {{ data?.main_button?.button_text }}
            </BaseButtonLink>
            <BaseLink
              v-if="!!data?.secondary_button?.button_text"
              class="md:w-fit"
              as="NuxtLink"
              underline
              :to="data?.secondary_button?.button_link"
            >
              {{ data?.secondary_button?.button_text }}
            </BaseLink>
          </div>
        </div>

        <BaseImage
          :src="data?.image?.original?.src"
          alt="Hero background"
          class="aspect-square !w-[325px] object-cover xl:self-end xl:-mt-52"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseTitle from '~/components/BaseTitle.vue';
import BaseLink from '~/components/BaseLink.vue';
import BaseImage from './BaseImage.vue';
import BaseButtonLink from './BaseButtonLink.vue';
import { useGTM } from '~/composables';
import { TRACKING_EVENTS } from '~/lib/constants';
import { useLocalePath } from '#imports';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const localePath = useLocalePath();

const titleChunks = computed(() => {
  const title: string = props.data?.title || '';

  return title.split('|');
});

const subtitleChunks = computed(() => {
  const subtitle: string = (props.data?.subtitle || '');

  return subtitle.split('.').filter(Boolean);
});

const { triggerEvent } = useGTM();
function onClickCta () {
  triggerEvent(TRACKING_EVENTS.CLICK_HERO_CTA);
}
</script>
